.pswp__custom-caption {
    background: rgba(65, 65, 65, 0.75);
    font-size: 16px;
    color: #fff;
    max-width: 400px;
    padding: 2px 8px;
    border-radius: 4px;
    position: absolute;
    left: 50%;
    bottom: 16px;
    transform: translateX(-50%);
}
