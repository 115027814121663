@tailwind base;
@tailwind components;
@tailwind utilities;

/* Customise the input fields*/
@layer base {
    /* form fields and button text size */
    [type="text"],
    [type="email"],
    [type="tel"],
    [type="password"],
    [type="number"],
    textarea,
    button {
        @apply text-base
    }

    /* form fields styling */
    [type="text"],
    [type="email"],
    [type="tel"],
    [type="password"],
    [type="number"],
    [type="date"],
    textarea {
        @apply border-[#ccc] focus:border-primary focus:ring-0 text-[#212121]
    }

    /* default font size */
    html {
        @apply text-base antialiased
    }

    @media (width <= 576px) {
        html {
            @apply text-sm
        }
    }

    body {
        @apply bg-[#f4f4f4] text-[#212121]
    }

    h1 {
        @apply text-[2.5rem];
    }

    h2 {
        @apply text-[2rem];
    }

    h3, h4 {
        @apply text-[1.5rem];
    }

    h5 {
        @apply text-[1.25rem];
    }

    h6 {
        @apply text-base;
    }

    p {
        @apply text-[#6f6f6f]
    }

}

/* Add here all your css styles (customizations) */
@layer components {
    .bg-normal {
        background-color: #f4f4f4;
    }

    .bottom-shadow {
        box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.4);
    }

    #vin-search > input:not([value='']) {
        @apply w-[220px]
    }

    #vin-search > input {
        @apply h-[36px] w-[36px] border border-gray-300 p-1 ps-[31px] text-sm focus:w-[220px]
    }

    .btn {
        @apply relative inline-block

    }

    .btn-primary {
        @apply text-white bg-primary

    }

    .form-control {
        height: calc(2.25rem + 2px);
    }

    .form-control:disabled {
        @apply bg-[#e9ecef]
    }

    .form-control-feedback {
        @apply text-red-500
    }

    .item {
        background-color: #ffffff;
        border-bottom: 1px solid #dcdcdc;
        border-left: 5px solid white;
        cursor: pointer;
    }

    .item-disabled, button:disabled {
        opacity: 0.5;
    }

    /*TODO: Revise styling as it does not well on gray icons*/
    button:enabled {
        @apply hover:saturate-[2]
    }

    .item-active {
        @apply border-l-[5px] border-l-primary bg-white
    }

    .item--hover:hover {
        @apply border-l-[5px] border-l-highlightColor
    }

    .loadingContainerOverlay {
        @apply absolute inset-0 size-full bg-white/[.9] z-10
    }

    .u-label {
        @apply text-sm
    }

    .u-info {
        position: relative;
    }

    .u-info::before {
        position: absolute;
        top: 20px;
        width: 85%;
        border-top: 1px dotted #ddd;
        content: " ";
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
    }

    .u-heading-v3-1 {
        border-bottom: 1px dotted #ccc;
    }

    .img-fluid {
        max-width: 100%;
        height: auto;
    }

    .color-btn-icon {
        color: #888;
    }

}

/* third-party libraries CSS */

/*https://cssloaders.github.io/*/
.loader {
    --size: 10px;
    --border: 2px;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    position: absolute;
    display: inline-block;
    animation: rotate 1s linear infinite
}

.loader::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0;
    border-radius: 50%;
    border: var(--border) solid #FFF;
    animation: prixClipFix 2s linear infinite;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg)
    }
}

@keyframes prixClipFix {
    0% {
        clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0)
    }
    25% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0)
    }
    50% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%)
    }
    75% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%)
    }
    100% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0)
    }
}


.react-phone-number-input__row input {
    border-bottom: none !important;
    background: transparent !important;
}

.react-phone-number-input__row button {
    border-bottom: none !important;
}

.react-phone-number-input__icon {
    height: auto !important;
    border: none !important;
}

.react-phone-number-input-focus {
    border: 1px solid #2d375a !important;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.ReactModal__Body--open {
    overflow: hidden;
}

.react-autosuggest__container {
    position: relative;
}

.react-autosuggest__input--focused {
    outline: none;
}

.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    border: 1px solid #aaa;
    background-color: #fff;
    z-index: 2;
    right: 0;
    top: 40px
}

@media (width <= 576px) {
    .react-autosuggest__suggestions-container--open {
        right: -74px;
    }
}

@media (577px <= width <= 768px) {
    .react-autosuggest__suggestions-container--open {
        right: -50px;
    }
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #f7f7f7;
    /*color: #FFFFFF;*/
}

/* contents from  custom.css */
/* Add here all your css styles (customizations) */


.content {
    flex: 1 0 auto !important; /* Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size. */
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    font-family: "Spectral", Helvetica, Arial, serif;
}


.color-highlight {
    color: #2d375a
}

.color-highlight-invert {
    color: #fff;
}

.brd-bg {
    border-color: #f4f4f4 !important;
}

.brd-highlight-bottom {
    border-bottom-color: #2d375a !important;
}

.badge {
    border-width: 1px;
    border-radius: 6px;
    padding: 2px 7px;
}

.badge-normal {
    border: 1px solid #dcdcdc;
    background-color: #f4f4f4;
    color: #212121;
    font-weight: normal;
}

.vat-highlight {
    border: 1px solid #c90502;
    background-color: #c90502;
    color: #fff;
}

.damage-highlight {
    border: 1px solid orange;
    background-color: orange;
    color: #fff;
}

.counter-badge-normal {
    @apply bg-primary border-primary text-white
}

.counter-badge-highlight {
    border: 1px solid orange;
    background-color: orange;
    color: #fff;
    font-weight: normal;
}


.div-overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    text-align: center;
}

.div-overlay div {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    left: 0px;
    right: 0px;
    font-size: 60px;
    color: #fff;
}

.upper-image-container {
    width: calc(50% - 4px);
    float: left;
    background-color: #fbfbfb;
    cursor: -webkit-zoom-in
}

.lower-image-container {
    width: calc((100% / 3) - 4px);
    float: left;
    background-color: #fbfbfb;
    cursor: -webkit-zoom-in;
}

.upper-image-container img, .lower-image-container img {
    width: 100%;
}

.align-right {
    text-align: right;
}

.damage-picture-thumbnail {
    width: 150px;
    height: 100px;
    float: right;
    cursor: -webkit-zoom-in;
}

.damage-picture-thumbnail-mobile {
    width: 380px;
    height: 320px;
    cursor: -webkit-zoom-in;
}

.damage-nopic {
    background-color: darkgrey;
}

.traffic-light {
    font-size: 15px;
    margin-right: 3px;
    align-self: center;
    color: #212121;
}

.traffic-light-red {
    color: #953034;
}

.traffic-light-orange {
    color: #f98500;
}

.traffic-light-green {
    color: #4aae00;
}

.filter-item-counter, .filter-item-check {
    @apply w-[36px];
}

.filter-item-counter-active {
    @apply bg-primary border-primary text-white
}

.filter-item:hover span {
    @apply bg-primary border-primary text-white
}

.dropdown div {
    padding: 3px;
}

.dropdown-no-border div {
    border: none;
    /*padding-right: 25 px;*/
}

.daterange div {
    display: block !important;
}

.interest-arrow {
    width: 0;
    height: 0;
    border-top: 30px solid transparent;
    border-bottom: 30px solid transparent;
    border-right: 30px solid #2d375a;
    transform: rotate(135deg);
}

.interest-container i {
    color: white;
}

.interest-container:hover i {
    color: rgba(255, 255, 255, 0.5);
}

.expectation {
    background-color: #f7f7f7;
    padding: 2px 0px 2px 0px;
}

.veh-description {
    color: darkgreen;
}

.veh-description-icon-important {
    color: #d23901;
}

.veh-description-icon-normal {
    color: darkgreen;
}

.container-important {
    border: 1px solid #d23901;
    color: #d23901;
    /*background-color: #f4db2a;*/

}

.container-normal {
    border: 1px solid darkgreen;
    color: darkgreen;

}

.tabs {
    display: inline-block;
    margin-right: 30px;
    vertical-align: top;
}

.tabs .tab-links {
    margin: 0;
    padding: 0;
    border-bottom: 2px solid #2d375a;
    height: 40px;
    position: relative;
    top: 5px;

}

.tabs .tab-link {
    padding: 0 30px;
    cursor: pointer;
    display: inline-block;
    outline: none !important;
    position: relative;
    height: 44px;
    background-color: white;
    line-height: 41px;
    top: -6px;

}

.tabs .tab-link-active {
    background-color: #2d375a;
    color: white;
}

.tabs .content {
    padding: 15px;
    border-top: 1px solid transparent;
    border-top: 1px solid transparent;
}


@media (min-width: 768px) {
    .u-timeline-v2-wrap::before {
        left: 17%;
        margin-left: -4px;

    }
}

@media (min-width: 768px) {
    .u-info-v9-1 {
        position: relative;
    }

    .u-info-v9-1::before {
        position: absolute;
        top: 20px;
        left: 10%;
        width: 80%;
        border-top: 1px dotted #ddd;
        content: " ";
    }
}

.registration-description {
    font-size: 14px;
    color: #888888;
    padding: 20px;
    text-align: center;
    line-height: 18px;
}

.error {
    border: 1px solid red;
}

.box-shadow {
    -webkit-box-shadow: 0px -13px 46px -25px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px -13px 46px -25px rgba(0, 0, 0, 0.75);
    box-shadow: 0px -13px 46px -25px rgba(0, 0, 0, 0.75);
}

.center-div {
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100px;
    height: 100px;
    background-color: #ccc;
    border-radius: 3px;
}

.center-right {
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
}

.center-left {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
}

.image-container-overlay {
    position: relative;
}

.image-overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}


@media (min-width: 768px) {


    div.stickyHeader {
        position: fixed;
        top: 0px;
        right: 0px;
        left: 0px;
        z-index: 100;
    }
}

@media (max-width: 768px) {
    div.sticky-md-down {
        position: sticky;
        position: -webkit-sticky;
        top: 0px;
        right: 0px;
        z-index: 50;
    }

    div.stickyHeader {
        position: absolute;
        top: 0px;
        right: 0px;
        left: 0px;
        z-index: 100;
    }

    .ReactModal__Content {
        padding: 5px !important;
    }
}

@media (max-width: 576px) {
    .h5 {
        font-size: 14px;
        line-height: 1;
    }
}


.click-text {
    cursor: pointer;
    text-decoration: underline;
}

@media (max-width: 1599px) {
    .hide-xl-down {
        display: none !important;
    }
}

#vehicle_search_popover {
    background-color: #2d375a;
}

.text-color-default {
    color: #777 !important;
}

.notifications-progress-bar {
    background: #2d375a;
}

.recharts-wrapper {
    width: auto !important;
}

.loading-indicator-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
}

.text-light-gr {
    color: #6f6f6f;
}


@media (min-width: 768px) {
    .font-size-13-important {
        font-size: 13px !important;
    }
}


.animationTest {
    transition: all 100ms ease-in-out;
}




