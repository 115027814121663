
.col-hidden {
  display: none !important;
}

@media (min-width: 576px) {
  .states-overflow-border {
    border-bottom: 1px solid #eee;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;

    margin: 0px 10px 10px 10px;
    padding: 2px;
  }
}
@media (max-width: 576px) {
  .states-overflow-border {
    text-align: center;
    margin-bottom: 10px;
  }
}
