.react-simple-input-container {
  position: relative;
}

.react-simple-input-clear {
  position: absolute;
  right: 15px;
  top: 1px;
  font-size: 20px;
  color: #777;
  font-weight: 800;
}
