.rating-star {
  display: inline-block;
  padding: 3px;
  vertical-align: middle;
  line-height: 1;
  font-size: 1.5em;
  color: #ccc;
  transition: color 0.2s ease-out;
}

.rating-star:hover {
  cursor: pointer;
}

.star-selected {
  color: #009acb;
}
