@font-face {
    font-family: 'icomoon';
    src:  url('fonts/icomoon.eot?nt0006');
    src:  url('fonts/icomoon.eot?nt0006#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?nt0006') format('truetype'),
    url('fonts/icomoon.woff?nt0006') format('woff'),
    url('fonts/icomoon.svg?nt0006#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-bluetooth:before {
    content: "\e900";
}
.icon-rearview-camera:before {
    content: "\e901";
}
.icon-alloy-wheels:before {
    content: "\e902";
}
.icon-pdc:before {
    content: "\e903";
}
.icon-tow-bar:before {
    content: "\e904";
}
.icon-cruise-control:before {
    content: "\e905";
}
.icon-xenon:before {
    content: "\e906";
}
.icon-spare-wheel:before {
    content: "\e907";
}
.icon-power-steering:before {
    content: "\e908";
}
.icon-open-roof:before {
    content: "\e909";
}
.icon-navigation:before {
    content: "\e90a";
}
.icon-mp3:before {
    content: "\e90b";
}
.icon-leather:before {
    content: "\e90c";
}
.icon-heated-seats:before {
    content: "\e90d";
}
.icon-airco:before {
    content: "\e90e";
}
.icon-pneumatic:before {
    content: "\e90f";
}
.icon-upload:before {
    content: "\e91f";
}
.icon-save:before {
    content: "\e91f";
}
.icon-map:before {
    content: "\e920";
}
.icon-location2:before {
    content: "\e920";
}
.icon-map-pin:before {
    content: "\e921";
}
.icon-pin:before {
    content: "\e921";
}
.icon-location:before {
    content: "\e921";
}
